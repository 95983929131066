import React from "react";
import Helmet from "react-helmet";
import { navigate } from "gatsby";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import NewOrderTablet from "../../components/order/newTablet";
import "bootstrap/scss/bootstrap.scss";

export default function NewKioskOrderPage() {
  var title = "";
  if (typeof window !== "undefined") title = localStorage.getItem("firstName") ? "Сайн байна уу, " + localStorage.getItem("firstName") : "";
  return (
    <div className="kiosk-new-order">
      <Helmet title="Илгээмж бүртгэх | SB Express" defer={false}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <style type="text/css">
          {`  
          html,
          body {
            position: fixed;
            overflow: hidden;
          }
        `}
        </style>
      </Helmet>
      <div className="px-4 py-3" style={{ overflowY: "auto", height: "100vh" }}>
        <div className="mb-3">
          <div>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                localStorage.clear();
                navigate("/kiosk");
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">{title}</div>
        </div>
        <NewOrderTablet />
      </div>
    </div>
  );
}
