import React, { useState } from 'react'
import { Row, Col, Radio, Button, Spin, notification, Modal, Form, Checkbox } from 'antd'
import { navigate } from 'gatsby'
import client from '../../api/client'
import PriceSelect from '../base/priceSelect'
import PartyChooser from './partyChooser'
import 'bootstrap/scss/bootstrap.scss'
import OrderSuccessImg from '../../images/ordersuccess.svg'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import OrderTypeSelection from './orderTypeSelection'
import { isOwner } from '../../api/auth'

export default function NewOrderTablet() {
  const [newOrderForm] = Form.useForm()
  const [orderSuccessModalVisible, setOrderSuccessModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [senderSelected, setSenderSelected] = useState(0)
  const [receiverSelected, setReceiverSelected] = useState(0)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([])
  const [errorValidateParty, setErrorValidateParty] = useState(false)
  const [errorValidateOrderType, setErrorValidateOrderType] = useState(false)
  const [errorValidateAmount, setErrorValidateAmount] = useState(false)
  const [cookie] = useCookies(['branchId'])

  const submitOrder = async () => {
    setLoading(true)
    const price = parseInt(selectedPrice)
    const tabletBranch = parseInt(cookie.branchId)
    if (!senderSelected || !receiverSelected) setErrorValidateParty(true)
    if (selectedOrderTypes.length === 0) setErrorValidateOrderType(true)
    if (isNaN(price)) setErrorValidateAmount(true)

    await newOrderForm
      .validateFields()
      .then(async (values) => {
        if (selectedOrderTypes.length !== 0 && senderSelected && receiverSelected && !isNaN(price)) {
          const data = {
            price,
            orderItemTypes: selectedOrderTypes,
            branchId: tabletBranch ? tabletBranch : 0,
            senderId: senderSelected,
            receiverId: receiverSelected,
            sourceType: 'Kiosk',
            shipmentType: values.shipmentType,
            paymentType: values.paymentType,
            willReceiveMessage: values.willReceiveMessage ?? false,
          }
          await client
            .post('/api/orders', data)
            .then(async (res) => {
              setOrderSuccessModalVisible(true)
            })
            .catch((error) => {
              console.error(error.message)
              notification.error({ message: 'Алдаа гарлаа' })
            })
        }
      })
      .catch((info) => {
        console.error('Validate Failed:', info)
      })
    setLoading(false)
  }
  return (
    <Spin spinning={loading}>
      <Form form={newOrderForm} initialValues={{ willReceiveMessage: true }} layout="vertical">
        <Row className="mb-1">
          <Col span={10} className="shipment-section">
            <Form.Item
              name="shipmentType"
              label="Илгээмжийн төрлөө сонгоно уу"
              rules={[{ required: true, message: 'Илгээмжийн төрлөө оруулна уу!' }]}
            >
              <Radio.Group className="sb-radio-buttons">
                <Radio value="Air">Агаарын ачаа</Radio>
                <Radio value="Ground">Газрын ачаа</Radio>
                {/* <Radio value="Express">Express ачаа</Radio> */}
                {isOwner() ? <Radio value="Container">Чингэлэгийн ачаа</Radio> : []}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24} className="party-section">
            <PartyChooser
              setSenderCallback={(e) => {
                setSenderSelected(e)
                if (receiverSelected) setErrorValidateParty(false)
              }}
              setReceiverCallback={(e) => {
                setReceiverSelected(e)
                if (senderSelected) setErrorValidateParty(false)
              }}
            />
            {errorValidateParty && <div style={errorMsgStyle}>Илгээгч болон хүлээн авагчаа сонгоно уу!</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24} className="mb-2 label-neworder" style={{ fontSize: 18 }}>
            <span style={{ display: 'inline-block', color: '#ff4d4f', fontSize: 14, fontFamily: 'SimSun, sans-serif' }}>*</span>
            <span> Явуулж буй илгээмжийн үнийн дүнгээ сонгоно уу:</span>
          </Col>
          <Col span={24} className="mb-1">
            <PriceSelect
              isPriceCustom={false}
              defaultValue={undefined}
              parentCallback={(e) => {
                setSelectedPrice(e)
                if (e) setErrorValidateAmount(false)
              }}
            />
            {errorValidateAmount && <div style={errorMsgStyle}>Илгээмжийн үнийн дүнгээ сонгоно уу!</div>}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={24}>
            <OrderTypeSelection
              isKiosk={true}
              defaultSelectedOrderTypes={false}
              refreshOrderItemTypeList={(selectedTypes) => {
                setSelectedOrderTypes(selectedTypes)
                if (selectedTypes.length > 0) setErrorValidateOrderType(false)
                else setErrorValidateOrderType(true)
              }}
            />
            {errorValidateOrderType && <div style={errorMsgStyle}>Илгээмжийн төрлөө сонгоно уу!</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <Form.Item
              name="paymentType"
              label="Төлбөрийн төрлөө сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Төлбөрийн төрлөө сонгоно уу!',
                },
              ]}
            >
              <Radio.Group className="sb-radio-buttons">
                <Radio value="Cash">Бэлэн</Radio>
                <Radio value="Transfer">Шилжүүлэг</Radio>
                <Radio value="Card">Карт</Radio>
                <Radio value="InMongolia">Монголд</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item valuePropName="checked" name="willReceiveMessage">
            <Checkbox className="kiosk-checkbox">
              <div style={{ marginLeft: 4, fontSize: 24 }}>Мэссэж болон и-мейл хүлээн авах уу?</div>
            </Checkbox>
          </Form.Item>
        </Row>
        <Row className="mb-5">
          <Col span={24}>
            <Button className="btn-sb btn-sb-primary w-100 mt-3" onClick={submitOrder} style={{ fontSize: 28, padding: 20, height: 'initial' }}>
              <FontAwesomeIcon icon={faPaperPlane} className="me-3"></FontAwesomeIcon>
              Илгээх
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        open={orderSuccessModalVisible}
        onCancel={() => navigate('/kiosk')}
        className="sb-modal sb-modal-kiosk"
        width={400}
        destroyOnClose
        centered
        maskStyle={{ background: '#11427FB9' }}
        footer={[
          <Row justify="center" key={'modal'} gutter={[12, 24]}>
            <Col span={12}>
              <Button className="btn-sb btn-sb-primary w-100" onClick={() => navigate('/kiosk')}>
                Дуусгах
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="btn-sb btn-sb-primary w-100"
                onClick={() => {
                  if (typeof window !== 'undefined') window.location.reload()
                }}
              >
                Дахин бүртгэх
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <div
          style={{
            height: '260px',
            paddingTop: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          <div style={{ position: 'absolute', top: '-68px', left: '143px' }}>
            <img src={OrderSuccessImg} alt="img" style={{ width: '180px' }} />
          </div>
          <div>
            <div style={{ fontSize: 22, textAlign: 'center' }}>Таны ачаа манай системд амжилттай бүртгэгдлээ.</div>
            <div style={{ fontSize: 16, textAlign: 'center' }}>Ачааны дэлгэрэнгүй мэдээллийг вэб порталаас харна уу.</div>
          </div>
        </div>
      </Modal>
    </Spin>
  )
}

const errorMsgStyle = { color: '#dc3545', fontSize: 20 }
